<template>
  <div class="foot">
    <div class="top">
      <div>首页</div>
      <div>产品服务</div>
      <div>关于我们</div>
    </div>
    <div class="middle">Graph AI Limited</div>
    <div class="top bottom">
      <div>
        <img src="../assets/foot/one.png" />
        <span>联系电话：0086 17896081969</span>
      </div>
      <div>
        <img src="../assets/foot/two.png" style="width: 22px" />
        <span
          >地址：RM A 12F ZJ300,300 LOCKHART<br />
          ROAD,WAN CHAI HK</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.foot {
  width: 100%;
  height: 326px;
  background: #0f2652;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    margin-bottom: 40px;
  }
  .top {
    display: flex;
    font-size: 24px;
    font-family: PingFangSC-Semibold, PingFang SC;
    div {
      padding: 0 50px;
      border-right: 1px solid #ffffff;
      &:last-child {
        border-right: none;
      }
    }
  }
  .middle {
    font-size: 28px;
    font-family: PingFangSC-Semibold, PingFang SC;
  }
  .bottom {
    font-size: 18px;
    font-family: MicrosoftYaHei;
    & > div {
      display: flex;
      align-items: flex-start;
    }
    img {
      width: 25px;
      margin-right: 10px;
    }
  }
}
</style>