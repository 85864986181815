<template>
  <div class="project">
    <header-nav :name="1"></header-nav>
    <div class="wrapper">
      <div class="width_app">
        <!-- 全球主流媒体渠道广告投放服务 -->
        <div>
          <h1 style="margin-top: 0">全球主流媒体渠道广告投放服务</h1>
          <div class="wrapper_flex">
            <p>
              我们可以把客户的广告投放到全球多个国家及地区,
              覆盖全球主流APP和网站媒体资源,配合第三方跟踪统计结果及防欺诈流量工具,
              助客户快速推广其产品或服务至全球，为客户提供高转化且精准的媒体广告投放服务。
            </p>
            <img src="../assets/project/one.png" alt="" />
          </div>
        </div>
        <!-- 服务内容 -->
        <div>
          <h1>服务内容</h1>
          <div class="wrapper_flex">
            <div class="title">
              <div>广告开户服务</div>
              <p>
                全球主流媒体渠道覆盖, 助你开拓目标市场及高价值用户。全面支持
                Google / Facebook / Tiktok / Youtube /
                Kwai等社交媒体及广告渠道。
              </p>
            </div>
            <img src="../assets/project/two.png" alt="" />
          </div>
          <div class="wrapper_flex">
            <div class="title">
              <div>广告代投服务</div>
              <p>
                Google, Facebook, Tiktok等各种DSP渠道投放 CPI, CPA, CPS 效果营销
                广告创意策划, 创意素材及文案
              </p>
            </div>
            <img src="../assets/project/three.png" alt="" />
          </div>
        </div>
        <!-- 全球主流媒体渠道广告投放服务 -->
        <div class="wrapper_three">
          <h1>全球主流媒体渠道广告投放服务</h1>
          <div class="flex">
            <div>
              <div>关键词定向</div>
              <p>根据关键词投放广告, 广告只会与关键字相关的媒体展示。</p>
            </div>
            <div>
              <div>国家定向</div>
              <p>定位目标国家和地区, 精确过滤受众, 从而提高投资回报率。</p>
            </div>
            <div>
              <div>设备定向</div>
              <p>
                投放广告到各种设备当中, 当中包括Android / IOS / PC /
                网络电视中。
              </p>
            </div>
            <div>
              <div>浏览器定向</div>
              <p>从制定特定访问者和排除列表, 将广告推送至特定用户。</p>
            </div>
            <div>
              <div>时间设定</div>
              <p>
                选择指定投放时段, 可指定一天之中的特定时间或一周之中特定的天数。
              </p>
            </div>

            <div>
              <div>兴趣类别</div>
              <p>只将广告展示到对该产品或服务感兴趣的用户</p>
            </div>
          </div>
        </div>
        <!-- 国际贸易服务 -->
        <div>
          <h1>国际贸易服务</h1>
          <div class="wrapper_flex">
            <p>
              我们在国际贸易服务领域深耕多年，拥有专业化的贸易服务团队和强大的贸易网络，服务区域遍及全球。
            </p>
            <img src="../assets/project/four.png" alt="" />
          </div>
          <div class="wrapper_flex">
            <div class="title">
              <div>电子产品</div>
              <p>
                我们的电子产品主要在南美市场销售，主要为高品质头戴式耳机、手环、无线充电器、感应灯、定位器等优质电子产品，我们始终坚持以最优惠的价格提供给客户最好的产品
              </p>
            </div>
            <img src="../assets/project/five.png" alt="" />
          </div>

          <div class="wrapper_flex">
            <div class="title">
              <div>美妆产品</div>
              <p>
                我们的美妆产品款式多样，主要有唇彩、唇釉、眼影盘、唇线笔、眼线笔等产品。选择我们，让美丽从零开始
              </p>
            </div>
            <div style="text-align: center">
              <img src="../assets/project/six.png" alt="" class="img_six" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import foot from "@/components/foot.vue";
export default {
  components: { headerNav, foot },

  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.project {
  .wrapper {
    background: #f8f9fb;
    padding: 70px 0 97px 0;
    box-sizing: border-box;
    h1 {
      margin: 64px 0 50px 0;
      padding: 0;
      font-size: 50px;
      text-align: center;
    }
    .wrapper_flex {
      display: flex;
      margin-bottom: 62px;
      font-size: 24px;
      color: #333333;
      font-family: PingFang-SC-Medium, PingFang-SC;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
      div {
        flex: 1;
      }

      .title {
        div {
          font-weight: 600;
        }
      }
      p {
        line-height: 38px;
        margin-right: 67px;
      }
      img {
        width: 603px;
      }
      .img_six {
        width: 500px;
      }
    }
    .wrapper_three {
      .flex {
        display: flex;
        flex-flow: wrap;
        & > div {
          height: 214px;
          width: 380px;
          margin-right: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #333333;
          padding: 0 52px;
          box-sizing: border-box;
          > div {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
          }
          p {
            font-size: 22px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            line-height: 30px;
            text-align: center;
          }
        }
        & > div:nth-child(odd) {
          background: #f3f5f9;
        }
        & > div:nth-child(even) {
          background: #f9f5f3;
        }
        & > div:nth-child(3n) {
          margin-right: 0;
        }
        & > div:nth-child(-n + 3) {
          margin-bottom: 27px;
        }
      }
    }
  }
}
</style>