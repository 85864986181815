import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import project from '@/views/project.vue'
import about from '@/views/about.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: "/index",
    },
    {
        path: '/index',
        name: 'index',
        component: index
    },
    {
        path: '/project',
        name: 'project',
        component: project
    },
    {
        path: '/about',
        name: 'about',
        component: about
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
