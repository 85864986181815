<template>
  <div class="header">
    <img src="../assets/header/logo.png" alt="" />
    <div>
      <el-button
        type="text"
        v-for="(item, index) in buttonList"
        :key="index"
        @click="handleButton(index)"
        :class="name == index ? 'active' : ''"
      >
        {{ item }}
      </el-button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      buttonList: ["首页", "产品服务", "关于我们"],
    };
  },
  props: {
    name: Number,
  },
  methods: {
    handleButton(index) {
      let url = "";
      switch (index) {
        case 0:
          url = "/index";
          break;
        case 1:
          url = "/project";
          break;
        case 2:
          url = "/about";
          break;
        // default:
        //   break;
      }
      this.$router.push(url).catch((err) => {
        err;
      });
    },
  },
};
</script>
<style  lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 359px;
  box-sizing: border-box;
  height: 90px;
  img {
    width: 256px;
  }
  > div {
    display: flex;
    .el-button {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      height: 43px;
      margin-left: 0;
      padding: 0 30px;
      box-sizing: border-box;
    }
    .active {
      background: #3475bf;
      color: #fff;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08),
        0px 4px 6px 0px rgba(50, 50, 93, 0.11);
      border-radius: 4px;
      margin-right: 25px;
      margin-left: 25px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>