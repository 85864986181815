<template>
  <div class="about">
    <header-nav :name='2'></header-nav>
    <div>
      <img src="../assets/about/one.png" alt="" class="img_one" />
      <div class="width_app wrapper">
        <div class="top">
          <img src="../assets/about/two.png" alt="" class="img_two" />
          <p>
            Graph AI
            Limited是一家2021年成立的科技型服务贸易公司，我们有丰富的从业经验以及最顶尖的技术团队。我们致力于为客户提供精准的广告投放和高品质的国际贸易服务，与我们合作的有国内外知名的企业。同时，我们还可以为客户量身打造高质量的、合适的服务产品及方案。此外我们可以根据客户需求定制适用于当地语言的产品及方案。我们的服务信条是：无限创想、品质服务。
          </p>
        </div>
        <div class="bottom">
          <h1>商务合作</h1>
          <img src="../assets/about/three.png" alt="" class="img_one" />
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import headerNav from "@/components/headerNav.vue";
import foot from "@/components/foot.vue";
export default {
  components: { headerNav, foot },

  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
<style  lang='scss' scoped>
.about {
  .img_one {
    width: 100%;
  }
  .wrapper {
    padding: 70px 0 100px 0;
    box-sizing: border-box;
    .top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      .img_two {
        width: 510px;
        margin-right: 67px;
      }
      p {
        font-size: 24px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        color: #333333;
        line-height: 38px;
      }
    }
    .bottom h1 {
      font-size: 50px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 70px;
      text-align: center;
      margin: 0;
      margin-bottom: 50px;
    }
  }
}
</style>