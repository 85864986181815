<template>
  <div class="index">
    <header-nav :name='0'></header-nav>
    <img src="../assets/index/one.png" class="banner" />
    <div class="wrapper">
      <div class="top">
        <h1>媒体渠道广告投放服务</h1>
        <div class="flex width_app">
          <p>
            我们可以把客户的广告投放到全球多个国家及地区，覆盖全球主流APP和网站媒体资源，配合第三方跟踪统计结果及防欺诈流量工具，助客户快速推广其产品或服务至全球，为客户提供高转化且精准的媒体广告投放服务。
          </p>
          <img src="../assets/index/two.png" alt="" class="img_two" />
        </div>
      </div>
      <div class="top top_two">
        <h1>国际贸易服务</h1>
        <div class="flex width_app">
          <img src="../assets/index/three.png" alt="" class="img_two" />
          <p class="p_two">
            我们在国际贸易服务领域深耕多年，拥有专业化的贸易服务团队和强大的贸易网络，服务区域遍及全球。
          </p>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>
<script>
import headerNav from "@/components/headerNav.vue";
import foot from "@/components/foot.vue";
export default {
  components: { headerNav, foot },
  data() {
    return {};
  },
};
</script>
<style  lang="scss" scoped>
.index {
  .banner {
    width: 100%;
  }
  .wrapper {
    .top {
      width: 100%;
      height: 638px;
      background: url("../assets/index/four.png") no-repeat;
      background-size: 100% 100%;
      h1 {
        text-align: center;
        font-size: 50px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        padding: 60px 0 50px 0;
        box-sizing: border-box;
        margin: 0;
      }
      .flex {
        display: flex;
        p {
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          color: #333333;
          line-height: 38px;
          margin-right: 45px;
        }
        .img_two {
          width: 614px;
        }
      }
    }
    .top_two {
      background: none;
      height: 668px;

      .p_two {
        margin-right: 0 !important;
        margin-left: 30px;
      }
    }
  }
}
</style>